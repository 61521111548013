import axios from 'axios';
import './App.scss';
import { useEffect, useState } from 'react';

const App = () =>  {
    const [pokemons, setPokemons] = useState([]);
    const [ready, setReady] = useState([]);
    const [box1, setBox1] = useState([]);
    const [box2, setBox2] = useState([]);
    const [box3, setBox3] = useState([]);
    const [box4, setBox4] = useState([]);
    const [box5, setBox5] = useState([]);   
    const [box6, setBox6] = useState([]);
    const [box7, setBox7] = useState([]);
    const [box8, setBox8] = useState([]);
    const [box9, setBox9] = useState([]);
    const [box10, setBox10] = useState([]);
    const [box11, setBox11] = useState([]);
    const [box12, setBox12] = useState([]);
    const [box13, setBox13] = useState([]);

    useEffect(()=>{
        axios.get("https://pokeapi.co/api/v2/pokemon/?limit=251")
        .then(response=>{
            setPokemons(response.data.results)
        }).catch(error=>console.error(error));
    }, []);

    useEffect(()=>{
        const r = localStorage.getItem("pokemon_progress");
        if(r !== null){
            const re = JSON.parse(r);
            setReady(re.pokemons);
        }else{
            setReady([]);
        }
    }, []);

    const addReady = (x) => {
        const newReady = [...ready];
        newReady.push(x)
        setReady(newReady);
        localStorage.setItem("pokemon_progress", JSON.stringify({pokemons: newReady}));
    }
    const removeReady = (x) => {
        const newReady = ready.filter(p => { return p !== x })
        setReady(newReady);
        localStorage.setItem("pokemon_progress", JSON.stringify({pokemons: newReady}));
    }
    useEffect(()=>{
        const b1 = pokemons.filter((x, index)=>{ return index < 20});
        setBox1(b1);
        const b2 = pokemons.filter((x, index)=>{ return index >= 20 && index < 40});
        setBox2(b2);
        const b3 = pokemons.filter((x, index)=>{ return index >= 40 && index < 60});
        setBox3(b3);
        const b4 = pokemons.filter((x, index)=>{ return index >= 60 && index < 80});
        setBox4(b4);
        const b5 = pokemons.filter((x, index)=>{ return index >= 80 && index < 100});
        setBox5(b5);
        const b6 = pokemons.filter((x, index)=>{ return index >= 100 && index < 120});
        setBox6(b6);
        const b7 = pokemons.filter((x, index)=>{ return index >= 120 && index < 140});
        setBox7(b7);
        const b8 = pokemons.filter((x, index)=>{ return index >= 140 && index < 160});
        setBox8(b8);
        const b9 = pokemons.filter((x, index)=>{ return index >= 160 && index < 180});
        setBox9(b9);
        const b10 = pokemons.filter((x, index)=>{ return index >= 180 && index < 200});
        setBox10(b10);
        const b11 = pokemons.filter((x, index)=>{ return index >= 200 && index < 220});
        setBox11(b11);
        const b12 = pokemons.filter((x, index)=>{ return index >= 220 && index < 240});
        setBox12(b12);
        const b13 = pokemons.filter((x, index)=>{ return index >= 240 && index < 260});
        setBox13(b13);
    }, [pokemons]);
    return (
        <div className="App">
            {(pokemons.length > ready.length) &&
            <div className='Pokemon-Counter'>
                <p style={{fontSize:"24px"}}>Tienes: {ready.length}</p>
                <p style={{fontSize: (265-ready.length)+"px"}}>Te faltan: {251-ready.length}</p>
            </div>
            }
            <div className='Pokemon-Container'>
                <div className='container-fluid'>
                    <div className="row">
                        {pokemons.map((x, index)=>(
                            <>
                                {!ready.includes(x.name) && 
                                    <div className='col-3 col-md-1'>
                                        <BtnPokemon info={x} key={index} addReady={addReady} />
                                    </div>
                                }
                            </>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div>
                {ready.map((x, index)=>(
                    <button key={index} onClick={()=>removeReady(x)}>
                        {x}
                    </button>

                ))}
            </div> */}
            <h6>Cajas</h6>
            <div className="container-fluid cajas">
                <div className="row">
                    <div className="col-6 col-md-2">
                        <h6>Caja 1</h6>
                        <div className="row">
                            {box1.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 2</h6>
                        <div className="row">
                            {box2.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 3</h6>
                        <div className="row">
                            {box3.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 4</h6>
                        <div className="row">
                            {box4.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 5</h6>
                        <div className="row">
                            {box5.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 6</h6>
                        <div className="row">
                            {box6.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 7</h6>
                        <div className="row">
                            {box7.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 8</h6>
                        <div className="row">
                            {box8.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 9</h6>
                        <div className="row">
                            {box9.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 10</h6>
                        <div className="row">
                            {box10.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 11</h6>
                        <div className="row">
                            {box11.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 12</h6>
                        <div className="row">
                            {box12.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <h6>Caja 13</h6>
                        <div className="row">
                            {box13.map((x, index)=>(
                                <div className="col-6">
                                    { ready.includes(x.name) ? <OnlyPokemon info={x} key={index} removeReady={removeReady} /> : <p>{x.name}</p> }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
const BtnPokemon = ({info, addReady}) => {
    const [pokemon, setPokemon] = useState({})
    useEffect(()=>{
        axios.get(info.url)
        .then(response=>{
            setPokemon(response.data)
        }).catch(error=>console.error(error));
    },[info])
    return(
        <div>
            <button onClick={()=>addReady(info.name)} className='btn-pokemon'>
                <img className='mw-100 w-100' src={pokemon?.sprites?.front_default} alt={info.name} /> 
            </button>
        </div>
    )
}
const OnlyPokemon = ({info, removeReady}) => {
    const [pokemon, setPokemon] = useState({})
    useEffect(()=>{
        axios.get(info.url)
        .then(response=>{
            setPokemon(response.data)
        }).catch(error=>console.error(error));
    },[info])
    return(
        <div>
            <img className='mw-100' src={pokemon?.sprites?.front_default} alt={info.name} onClick={()=>removeReady(info.name)} />
        </div>
    )
}
export default App;
